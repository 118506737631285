<template>
  <div class="background"  @click="closePopUp">
    <div class="card-container" style="cursor: default" v-on:click.stop>
      <div class="title">Новая почта</div>
      <div class="divider"></div>
      <div class="card-content">
        <div class="v-search-bar">
          <input class="edittext" type="text" placeholder="E-Mail" v-model="this.email"/>
        </div>
        <div class="btn" @click="change()">Изменить</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pop-up-change-email",
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    closePopUp() {
      this.$emit('close')
    },
    change() {
      this.$emit('changeEmail', this.email)
    }
  }
}
</script>

<style scoped>
.background {
  cursor: pointer;
  background: rgba(4, 26, 61, 0.4);
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.edittext {
  width: calc(100% - 32px);
  font-family: "Mont", serif;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #101010;
  padding-top: 8px;
  padding-bottom: 6px;
  margin: 32px 16px 16px 16px;
  text-align: center;
  border-width: 0;
  outline: none;
  height: 32px;
  background: #f3f3f3;
  border-radius: 12px;
}

.card-container {
  width: 400px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  overflow-y: hidden;
  height: max-content;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
  display: inline-block;
  grid-template-columns: 64px 1fr;
  grid-template-rows: 64px 1px 1fr;
  grid-template-areas:
      "back title"
      "divider divider"
      "content content";
}

.card-container .title {
  text-align: center;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #2D666E;
  grid-area: title;
  align-self: center;
  margin-top: 12px;
  margin-bottom: 16px;
  justify-self: center;
  font-size: 1.5rem;
}

.card-container .divider {
  background: #e7e7e7;
  height: 1px;
  grid-area: divider;
  width: 100%;
}

.card-container .card-content {
  grid-area: content;
}

.btn {
  cursor: pointer;
  border-radius: 10px;
  align-self: center;
  background: #1BA948;
  color: white;
  font-family: "Mont", serif;
  padding: 12px 24px 10px 24px;
  margin: 16px 16px 16px 16px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

</style>