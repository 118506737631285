<template>
  <page-scaffold>
    <pop-up-message message="Ваша заявка принята" v-if="isMessageVisible" @close="isMessageVisible=false"></pop-up-message>
    <card-content :is-loading="isLoading" :name="'Тарифы ('+this.title+')'" style="position: relative">

      <div id="cont" v-if="!isChanging"
           style="height: calc(100% - 64px); padding: 32px 64px; display: flex; overflow-x: hidden; position: relative">

        <div :style="{'margin-left': '-' + (400*currentIndex) + 'px'}" style="width: 0; transition: all ease 750ms"/>

        <item-tariff @clickConnectTV="changeTariffTV(tariff.id)" @clickConnectNet="changeTariffNet(tariff.id)"
                     v-for="tariff in tariffs" v-bind:key="tariff.id"
                     :tariff="tariff" :is-tv="isTv"
                     style="transition: all ease 750ms;"/>

        <img v-if="!isLoading&&!isEmptyList" class="btn-right" @click="swipeRight()"
             :src="require('@/assets/ic_arrow_tariffs_right.svg')"
             :style="'opacity:'+rightOpacity+'%'"/>
        <img v-if="!isLoading&&!isEmptyList" class="btn-left" @click="swipeLeft()"
             :src="require('@/assets/ic_arrow_tariffs_left.svg')"
             :style="'opacity:'+leftOpacity+'%'"/>
      </div>

      <div v-if="isChanging" class="changing"
           style="text-align: center; width: max-content; height: max-content; position: absolute; margin: auto; top: 0; left:0; right: 0; bottom: 0">
        <img :src="require('@/assets/ic_tariff_changing.svg')"/>
        <div class="title">Происходит смена тарифа<br>С {{ scheduledTariffChanges.currentTariffTitle }} на
          {{ scheduledTariffChanges.newTariffTitle }}
        </div>
        <div class="date">Ожидаемая дата смены тарифа: {{ scheduledTariffChanges.changesDateString }}</div>
        <div class="btnn" @click="cancelChanging">Отменить переход</div>
      </div>

      <empty-list-message v-if="isEmptyList"/>

    </card-content>
  </page-scaffold>


</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import ItemTariff from "./item-tariff";
import axios from "axios";
import EmptyListMessage from "./EmptyListMessage";
import PopUpMessage from "./pop-up-message";

export default {
  name: "page-internet-tariffs",
  components: {PopUpMessage, EmptyListMessage, ItemTariff, CardContent, PageScaffold},
  data: () => {
    return {
      isEmptyList: false,
      isLoading: true,
      isChanging: false,
      scheduledTariffChanges: Object,
      title: "Интернет",
      isTv: Boolean,
      url: '',
      currentIndex: 0,
      rightOpacity: 100,
      leftOpacity: 30,
      tariffs: Array,
      isMessageVisible: false
    }
  },
  methods: {
    cancelChanging() {
      this.isLoading = true
      axios({
        method: "DELETE",
        url: "v1/tariffs/internet",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(() => {
        this.getTariffs()
      })
    },

    changeTariffTV(id) {
      let self = this
      this.isLoading = true
      axios({
        method: "PUT",
        url: "v1/tariffs/tv",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          newTariffID: id
        }
      }).then(response => {
        if (response.data.status === "OK"){
          self.isMessageVisible = true
        }else {
          window.open('https://24h.tv/itk#connect-form', '_blanc')
        }
        self.isLoading = false
      })
    },

    changeTariffNet(id) {
      this.isLoading = true
      axios({
        method: "PUT",
        url: "v1/tariffs/internet",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          newTariffID: id
        }
      }).then(response => {
        console.log(response)
        this.getTariffs()
      })
    },

    swipeRight() {
      var parentElem = document.getElementById('cont').parentNode;
      var parentWidth = parentElem.offsetWidth;
      if (this.currentIndex < this.tariffs.length - Math.floor(parentWidth / 400)) {
        this.currentIndex++
        this.leftOpacity = 100
      }
      if (this.currentIndex === this.tariffs.length - Math.floor(parentWidth / 400)) {
        this.rightOpacity = 30
      } else
        this.rightOpacity = 100
    },

    swipeLeft() {
      if (this.currentIndex > 0) {
        this.rightOpacity = 100
        this.currentIndex--
      }
      if (this.currentIndex === 0) {
        this.leftOpacity = 30
      } else {
        this.leftOpacity = 100
      }

    },
    getTariffs() {
      this.isEmptyList = false
      this.isLoading = true
      axios({
        method: "GET",
        url: this.url,
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        this.isLoading = false
        if (this.isTv)
          this.tariffs = response.data
        else {
          if (response.data.status === "hasActiveSchedule") {
            this.isChanging = true
            this.scheduledTariffChanges = response.data.scheduledTariffChanges;
          } else {
            this.isChanging = false
            this.tariffs = response.data.tariffs
            if (this.tariffs.length === 0)
              this.isEmptyList = true
          }
          this.isLoading = false
        }
      })
    }
  },
  mounted() {
    switch (this.$route.query.type) {
      case "internet":
        this.isTv = false
        this.title = "Интернет"
        this.url = "v1/tariffs/internet"
        break;
      case
      "tv":
        this.isTv = true
        this.url = "v1/tariffs/tv"
        this.title = "Телевидение"
        break;
      default:
        this.isTv = false
        this.url = "v1/tariffs/internet"
        this.title = "Интернет"
    }
    this.getTariffs()


  }
}
</script>

<style scoped>

.title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #4d9ec4;
  justify-self: center;
  font-size: 1.5rem;
}

.date {
  margin-top: 16px;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #858585;
  justify-self: center;
  font-size: 1.2rem;
}

.btnn {
  cursor: pointer;
  margin-top: 16px;
  border-radius: 10px;
  background: #58B1DA;
  color: white;
  justify-self: center;
  font-family: "Mont", serif;
  padding: 16px 24px 13px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.btn-left {
  cursor: pointer;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  height: max-content;
  position: absolute;
}

.btn-right {
  cursor: pointer;
  height: max-content;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
</style>
