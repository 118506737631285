<template>
  <pop-up-confirm-phone v-if="isConfirmPhoneVis" v-on:close="isConfirmPhoneVis=false" @confirmPhone="confirmPhone"/>
  <pop-up-confirm-email v-if="isConfirmEmailVis" v-on:close="isConfirmEmailVis=false" @confirmEmail="confirmEmail"/>
  <pop-up-change-email v-if="isEmailVis" v-on:close="isEmailVis=false" @changeEmail="changeEmail"/>
  <pop-up-change-phone v-if="isPhoneVis" v-on:close="isPhoneVis=false" @changePhone="changePhone"/>
  <pop-up-change-pass v-if="isNewPasswordVisible" v-on:close="isNewPasswordVisible=false" @changePass="changePass"/>
  <pop-up-message v-if="isMessageVisible" :message="message" @close="isMessageVisible=false"/>


  <page-scaffold>
    <card-content :is-loading="isLoading" :name="isPhysical? 'Мой профиль' : 'Профиль организации'">
      <div class="profile-content">
        <img class="image"
             :src="isPhysical? require('@/assets/ic_account_large.svg') : require('@/assets/ic_company_large.svg')"
             alt="">
        <div class="data">
          <div v-if="isPhysical&&!isLoading" class="text">
            <div style="font-size: 2.5rem; line-height: 2.5rem; margin-top: 16px">{{ account.userName }}</div>
            <div style="font-size: 1.6rem; margin-top: 32px">Баланс: {{ account.balance }} ₽</div>
            <div style="font-size: 1.6rem; margin-top: 8px">Баллы: {{ account.points }} Б</div>

            <div style="font-size: 1.3rem; margin-top: 32px">Договор №: {{ account.agreementNumber }}</div>
            <div style="font-size: 1.3rem; margin-top: 8px">Лицевой счет №: {{ account.personalAccount }}</div>
            <div style="font-size: 1.3rem; margin-top: 8px">Адрес: {{ account.address }}
            </div>

            <div style="font-size: 1.3rem; margin-top: 32px">Мобильный телефон: <u @click="isPhoneVis=!isPhoneVis"
                                                                                   style="cursor: pointer">{{
                account.phone
              }}
            </u></div>
            <div style="font-size: 1.3rem; margin-top: 8px">Email: <u @click="isEmailVis=!isEmailVis"
                                                                      style="cursor: pointer">{{
                getEmail()
              }}</u></div>
          </div>

          <div v-if="!isPhysical&&!isLoading" class="text" style="overflow-y: hidden;">
            <div style="font-size: 2.3rem; line-height: 2.5rem; margin-top: 16px">{{ account.userName }}</div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 32px">Баланс: {{ account.balance }} ₽</div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 8px">Баллы: {{ account.points }} Б</div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 32px">Договор №:
              {{ account.agreementNumber }}
            </div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 32px;">Адрес подключенных объектов :
              <div v-for="(adrs, index) in legalEntityData.addresses" v-bind:key="index"
                   style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 8px; color: #60646c">⊚ {{ adrs }}<br/>
              </div>
            </div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 16px">Адрес для отправки почты:
            </div>
            <div
                style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 8px; color: #60646c">⊚
              {{ legalEntityData.postalAddress }}<br/>
            </div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 32px">Телефон 1: <u
                @click="isPhoneVis=!isPhoneVis" style="cursor: pointer">{{ account.phone }}</u>
            </div>
            <div style="font-size: 1.3rem;line-height: 1.3rem;  margin-top: 8px">Телефон 2: <u
                style="cursor: default">{{
                legalEntityData.additionPhone ? legalEntityData.additionPhone : "Отсутствует"
              }}</u>
            </div>
            <div style="font-size: 1.3rem; margin-top: 8px">Email: <u @click="isEmailVis=!isEmailVis"
                                                                      style="cursor: pointer">Добавить</u></div>

            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 32px">ИНН: {{ legalEntityData.INN }}</div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 8px">ОГРН: {{ legalEntityData.OGRN }}</div>
            <div style="font-size: 1.3rem; line-height: 1.3rem; margin-top: 8px">ФИО Руководителя:
              {{ legalEntityData.directorName }}
            </div>
          </div>
          <div v-if="!isLoading" class="btn" @click="isNewPasswordVisible=true">Сменить пароль</div>

        </div>
      </div>
    </card-content>
  </page-scaffold>

</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import axios from "axios";
import PopUpChangePhone from "./pop-up-change-phone";
import PopUpChangeEmail from "./pop-up-change-email";
import PopUpConfirmPhone from "./pop-up-confirm-phone";
import PopUpConfirmEmail from "./pop-up-confirm-email"
import PopUpMessage from "./pop-up-message";
import PopUpChangePass from "./pop-up-change-pass";

export default {
  name: "page-profile",
  data: () => {
    return {
      isNewPasswordVisible: false,
      isMessageVisible: false,
      isLoading: true,
      message: '',
      isPhysical: localStorage.userType === "individual",
      account: Object,
      legalEntityData: Object,
      isEmailVis: false,
      isPhoneVis: false,
      isConfirmPhoneVis: false,
      isConfirmEmailVis: false,
      recordID: 0
    }
  },
  components: {
    PopUpChangePass,
    PopUpMessage, PopUpConfirmPhone, PopUpChangeEmail, PopUpChangePhone, CardContent, PageScaffold, PopUpConfirmEmail
  },
  methods: {
    getEmail() {
      if (this.account.email === "" || this.account.email === null) {
        return "Добавить"
      } else return this.account.email
    },

    changePhone(phone) {
      this.isLoading = true
      axios({
        method: "PUT",
        url: "v1/account/phone",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          newPhone: phone
        }
      }).then(response => {
            if (response.data.status === "error") {
              this.message = response.data.userMessage
              this.isPhoneVis = false
              this.isLoading = false
              this.isMessageVisible = true
            } else {
              this.recordID = response.data.recordID
              this.isPhoneVis = false
              this.isLoading = false
              this.isConfirmPhoneVis = true
            }
          }
      )
    },

    confirmPhone(code) {
      this.isConfirmPhoneVis = false
      this.isLoading = true
      axios({
        method: "POST",
        url: "v1/account/confirmPhone",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          recordID: this.recordID,
          confirmationCode: code
        }
      }).then(response => {
        if (response.data.status === "error") {
          this.message = response.data.userMessage
          this.isLoading = false
          this.isMessageVisible = true
        } else {
          axios({
            method: "GET",
            url: "v2/account",
            headers: {Authorization: `Bearer ` + localStorage.token},
          }).then(response => {
            this.isLoading = false
            this.account = response.data.account
            this.legalEntityData = response.data.account.legalEntityData
          })
        }
      })
    },

    confirmEmail(code) {
      this.isConfirmEmailVis = false
      this.isLoading = true
      axios({
        method: "POST",
        url: "v1/account/confirmEmail",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          recordID: this.recordID,
          confirmationCode: code
        }
      }).then(response => {
        if (response.data.status === "error") {
          this.message = response.data.userMessage
          this.isLoading = false
          this.isMessageVisible = true
        } else {
          axios({
            method: "GET",
            url: "v2/account",
            headers: {Authorization: `Bearer ` + localStorage.token},
          }).then(response => {
            this.isLoading = false
            this.account = response.data.account
            this.legalEntityData = response.data.account.legalEntityData
          })
        }
      })
    },

    changeEmail(email) {
      this.isLoading = true
      axios({
        method: "PUT",
        url: "v1/account/email",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          newEmail: email
        }
      }).then(response => {
            if (response.data.status === "error") {
              this.message = response.data.userMessage
              this.isEmailVis = false
              this.isLoading = false
              this.isMessageVisible = true
            } else {
              this.recordID = response.data.recordID
              this.isEmailVis = false
              this.isLoading = false
              this.isConfirmEmailVis = true
            }
          }
      )
    },
    closePass() {
      this.isNewPasswordVisible = false
    },
    changePass(oldpass, pass) {
      this.isLoading = true
      axios({
        method: "PUT",
        url: "v1/account/password",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          oldPassword: oldpass,
          newPassword: pass,
        }
      }).then(response => {
            if (response.data.status === "error") {
              this.message = response.data.userMessage
              this.isNewPasswordVisible = false
              this.isLoading = false
              this.isMessageVisible = true
            } else {
              this.isNewPasswordVisible = false
              this.isLoading = false
            }
          }
      )
    }
    ,
    getAccount() {
      axios({
        method: "GET",
        url: "v2/account",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        this.isLoading = false
        this.account = response.data.account
        this.legalEntityData = response.data.account.legalEntityData
      })
    }
  },
  mounted() {
    this.isLoading = true
    this.getAccount()
  }
}
</script>

<style scoped>

.profile-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
}

.profile-content .image {
  margin-left: 64px;
  margin-right: 64px;
  object-fit: contain;
  width: calc(100% - 128px);
  height: 100%;
}

.profile-content .data {
  display: grid;
  grid-template-rows: 1fr max-content;
  padding: 24px;

}

.profile-content .data .text {
  text-align: start;
  font-family: "Mont", serif;
  font-weight: bold;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.profile-content .btn {
  margin-top: 32px;
  cursor: pointer;
  border-radius: 16px;
  align-self: center;
  box-shadow: 0 0 16px rgba(17, 115, 47, 0.6);
  background: #1BA948;
  color: white;
  font-family: "Mont", serif;
  padding: 16px 24px 16px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

</style>