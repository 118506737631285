<template>
  <pop-up-message :message="message" v-if="isMessageVisible" v-on:close="isMessageVisible=false"/>

  <page-scaffold>
    <div class="balance-page">
      <!--      <div class="title" style="grid-area: title-balance">Мой баланс</div>-->
      <div class="title" style="color: white; grid-area: title-history">История платежей</div>
      <!--      <div class="title"-->
      <!--           style="grid-area: balance; margin-top: 12px; font-size: 4rem; line-height: 4rem;  font-family: 'Mont', serif; color: #58B1DA">-->
      <!--        214 ₽-->
      <!--      </div>-->
      <div class="balance-up" style="margin-top: 32px; height: 100%">
        <div class="title" style="margin-top: 0">Пополнить баланс</div>
        <div class="v-search-bar">
          <input class="sum" type="text" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" placeholder="0₽"
                 v-model="amount"/>
        </div>
        <div class="btn" @click="pay">Пополнить</div>
        <a style="width: max-content; text-decoration: none" href="https://node2.online.sberbank.ru/">
          <div class="btn-text" style="cursor: pointer;">Автоплатеж</div>

        </a>
      </div>
      <div class="history">
        <item-payment v-for="item in history" v-bind:key="item.id" :item="item"/>
      </div>
    </div>


  </page-scaffold>


</template>

<script>
import PageScaffold from "./page-scaffold";
import ItemPayment from "./item-payment";
import axios from "axios";
import PopUpMessage from "./pop-up-message";

export default {
  name: "page-balance",
  components: {PopUpMessage, ItemPayment, PageScaffold},
  data: () => {
    return {
      history: Array,
      amount: "",
      message: "Укажите сумму платежа",
      isMessageVisible: false
    }
  },
  methods: {
    pay() {
      var isPhys =localStorage.userType === 'individual'
      if (isPhys) {
        if (this.amount !== "")
          if (parseFloat(this.amount) > 0)
            axios({
              method: 'GET',
              params: {
                amount: this.amount,
                redirectUrl: "https://k.itcru.ru/balance"
              },
              url: "v1/payment",
              headers: {Authorization: `Bearer ` + localStorage.token},
            }).then(response => {
              window.open(response.data.paymentUrl, "_self")
            })
          else {
            this.message = "Сумма платежа должна быть больше 0 рублей"
            this.isMessageVisible = true
          }
        else {
          this.message = "Укажите сумму платежа"
          this.isMessageVisible = true
        }
      }else {
        this.$router.push("/documents?type=1")
      }
    },
  },
  mounted() {
    axios({
      method: "GET",
      url: "v1/payment/history",
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      this.history = response.data.response
    })
  }
}
</script>

<style scoped>
.balance-page {
  padding-right: 24px;
  width: calc(100%);
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas:
      "balancewidget title-history"
      "balancewidget history"
      "balancewidget history"
}


.balance-up {
  margin-left: 16px;
  text-align: center;
  padding: 16px 0;
  background: #FFFFFF;
  margin-right: 16px;
  height: max-content;
  width: 350px;
  border-radius: 24px;
  grid-area: balancewidget;
}

.balance-up .sum {
  width: calc(100% - 48px);
  font-family: "Mont", serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 8px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
  border-width: 0;
  outline: none;
  height: 32px;
  background: #cccccc;
  border-radius: 16px;
}


.title {
  padding-left: 16px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #000000;
  height: max-content;
  justify-self: start;
  white-space: nowrap;
  font-size: 1.4rem;
  margin-top: 24px;
  margin-bottom: 24px;
}

.history {
  grid-area: history;
  overflow-y: scroll;
  padding: 16px;
}

.history::-webkit-scrollbar {
  width: 0;
  height: 0;
}


.btn {
  background-image: linear-gradient(90deg, #1BA948 0%, #71B280  51%, #1BA948  100%);
  cursor: pointer;
  padding: 16px 14px 14px 14px;
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 16px;
  box-shadow: 0 0 24px rgba(255, 255, 255, 0.3);
  width: calc(100% - 48px - 28px);
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  font-size: 1rem;
}

.btn:hover {
  box-shadow: 0 0 24px rgba(47, 255, 0, 0.6);
}

.btn-text {
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  color: #000000;
  font-size: 1rem;
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.97);
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: white;
  opacity: 0.5;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: white;
  opacity: 0.5;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(63, 61, 61, 0.5);
}

::placeholder { /* Most modern browsers support this now. */
  color: rgba(63, 61, 61, 0.5);
}
</style>
