<template>
  <div class="card">
    <div class="text1" style="margin-top: 8px">{{ tariff.title }}</div>
    <div class="text1" style="margin-top: 8px; color: #2F7393; font-size: 1.3rem">{{ getSecondParam() }}</div>
    <div class="text" style="margin-top: 4px; margin-bottom: 4px">{{ tariff.description }}</div>
    <div class="detail" style="display: flex; margin-bottom: 0px" v-for="spec in tariff.specifications"
         v-bind:key="spec.id">
      <div style=" font-size: 1.6rem" :style="'color:'+spec.colorHEX">◉</div>
      <div style="margin-left: 8px; margin-top: 6px; margin-right: 16px">{{ spec.title }}</div>
    </div>
    <div class="text1" style="font-size: 1.3rem; margin-top: 16px; margin-bottom: 72px">Цена: {{ tariff.price }} ₽/мес</div>
    <div class="btn-container" @click="connect()">
      <div class="text" >
        Подключить
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "item-tariff",
  props: {
    tariff: Object,
    isTv: Boolean
  },
  methods: {
    connect() {
      if (this.isTv)
        this.$emit("clickConnectTV")
        // window.open('https://24h.freshdesk.com/support/solutions/folders/35000206389', '_blank')
      else
        this.$emit("clickConnectNet")
    },
    getSecondParam() {
      if (this.isTv) {
        return "Каналов: до " + this.tariff.channelsQuantity;
      } else
        return "Скорость: до " + this.tariff.maxSpeed + " Мбит/с";
    },

  }
}
</script>

<style scoped>
.card {
  /*display: inline-block;*/
  margin-right: 32px;
  padding: 24px;
  position: relative;
  height: calc(100% - 48px);
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  background: white;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
  text-align: start;
}


.text1 {
  font-family: "Mont", serif;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.8rem;
  color: black;

}

.btn-container {
  position: absolute;
  bottom: 20px;
  width: 224px;
  cursor: pointer;

  height: 40px;
  border-radius: 12px;
  padding: 0 48px;
  display: grid;
  box-shadow: 0 0 16px rgba(17, 115, 47, 0.6);
  background: #1BA948;

}

.btn-container .text {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  align-self: center;
  justify-self: center;

}


</style>