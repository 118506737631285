<template>
  <div class="page-scaffold">
    <top-bar style="grid-area: top-bar" :name="name"/>
    <div class="safe-zone" style="grid-area: safe-zone-l"/>
    <slot class="content" style="grid-area: content">

    </slot>
    <div class="safe-zone" style="grid-area: safe-zone-r"/>
  </div>

</template>

<script>
import TopBar from "./top-bar";

export default {
  name: "page-scaffold",
  data() {
    return {
      name: localStorage.userNamme
    }
  },
  components: {
    TopBar
  },
  mounted() {
    this.name = localStorage.userName
  }
}
</script>

<style scoped>
.page-scaffold {
  background: #141414;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1400px) 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
      "safe-zone-l top-bar safe-zone-r"
      "safe-zone-l content safe-zone-r";
}

.page-scaffold .safe-zone {
}

.page-scaffold .content {
}
</style>