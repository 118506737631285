<template>
  <pop-up-get-password v-if="isPopupPasswordVisible" v-on:close="isPopupPasswordVisible=false"
                       @getPassword="getPassword"/>
  <pop-up-confirm-pass v-if="isConfirmPassVis" v-on:close="isConfirmPassVis=false" @confirmPass="confirmPassword"/>
  <pop-up-message :message="message" v-if="isMessageVisible" v-on:close="isMessageVisible=false"/>
  <pop-up-loading v-if="isLoading"/>

  <div class="login-page">
    <div class="container">
      <img class="logo" :src="require('@/assets/logo_itc.png')"/>
      <div class="title">Личный кабинет</div>
      <div class="field-container">
        <img class="icon" :src="require('@/assets/ic_email.svg')"/>
        <input id="email" type="tel" data-tel-input placeholder="Логин" maxlength="20" v-model="login"/>
      </div>
      <div class="field-container" style="margin-top: 16px">
        <img class="icon" :src="require('@/assets/ic_password.svg')"/>
        <input id="password" type="password" placeholder="Пароль" maxlength="30" v-model="pass"/>
      </div>
      <div  class="title" style="text-align: end; font-size: 0.9rem; color: #696969; margin-top: 8px; justify-self: end; height: fit-content; cursor: pointer"
           @click="isPopupPasswordVisible=true">Забыли пароль?
      </div>
      <div class="auth-btn" @click="signIn">
        Войти
      </div>
    </div>

  </div>

</template>

<script>

// import APIService from "../service/APIService";

import axios from "../axios";
import PopUpMessage from "./pop-up-message";
import PopUpGetPassword from "./pop-up-get-password";
import PopUpLoading from "./pop-up-loading"
import PopUpConfirmPass from "./pop-up-confirm-pass";

export default {
  name: "page-login",
  components: {PopUpConfirmPass, PopUpGetPassword, PopUpMessage, PopUpLoading},
  data() {
    return {
      isMessageVisible: false,
      isConfirmPassVis: false,
      isLoading: false,
      recordID: 0,
      isPopupPasswordVisible: false,
      message: '',
      xmlHttp: null,
      login: '',
      pass: ''
    }
  },
  methods: {
    getPassword(number, isPhone) {
      this.isLoading = true
      axios({
        method: "POST",
        url: "v1/auth/passwordRecovery",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          login: number,
          recoveryMethod: isPhone ? "SMS" : "EMAIL"
        }
      }).then(response => {
        console.log()
        if (response.data.status === "error") {
              this.message = response.data.userMessage
              this.isNewPasswordVisible = false
              this.isLoading = false
              this.isMessageVisible = true
            } else {
              this.recordID = response.data.recordID
              this.isNewPasswordVisible = false
              this.isLoading = false
              this.isConfirmPassVis = true
            }
          }
      )
    },

    confirmPassword(code) {
      this.isConfirmPassVis = false
      this.isLoading = true
      axios({
        method: "POST",
        url: "v1/auth/passwordRecovery/confirm",
        headers: {Authorization: `Bearer ` + localStorage.token},
        params: {
          recordID: this.recordID,
          acceptCode: code
        }
      }).then(response => {
        if (response.data.status === "error") {
          this.message = response.data.userMessage
          this.isLoading = false
          this.isMessageVisible = true
        } else {
          this.message = "Успешно"
          this.isLoading = false
          this.isMessageVisible = true
        }
      })
    },

    signIn() {
      const self = this
      axios({
        method: "POST",
        url: "v1/auth",
        params: {
          login: this.login,
          pass: this.pass
        },
      }).then(response => {
        if (response.data.status === "OK") {
          localStorage.token = response.data.access_token
          self.$router.push("/")
        } else {
          this.message = response.data.userMessage
          this.isMessageVisible = true
        }
      })
    }
  }
}
</script>

<style scoped>

.title {
  padding-left: 16px;
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #212121;
  height: max-content;
  white-space: nowrap;
  font-size: 1.4rem;
  margin-bottom: 40px;
}

.login-page {
  top: 0;
  left: 0;
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  width: calc(100%);
}

.login-page .container {
  /*display: grid;*/
  position: absolute;
  height: max-content;
  display: inline-block;
  max-width: 300px;
  min-width: 200px;
  padding: 16px;
  top: 16px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  margin: auto;;

}

.login-page .field-container {
  text-align: start;
  width: calc(100% - 36px);
  display: inline-block;
  border-width: 2px;
  border-radius: 8px;
  padding: 8px 16px;
  border-style: solid;
  border-color: #1BA948;
}

.login-page .container .logo {
  justify-self: center;
  min-width: 150px;
  horiz-align: center;
  margin-bottom: 32px;
  width: 80%;
}

.login-page .field-container input {
  height: 100%;
  color: #1BA948;
  outline: none;
  font-weight: bold;
  border: 0;
  font-size: 1rem;
  vertical-align: center;
}

.login-page .field-container .icon {
  max-height: 24px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: bottom;
}

.login-page .container .auth-btn {
  box-shadow: 0 0 16px rgba(17, 115, 47, 0.6);

  font-family: "Century Gothic", serif;
  vertical-align: bottom;
  width: calc(100% - 32px);
  text-align: center;
  margin-top: 32px;
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
  padding: 10px 16px;
  background: #1BA948;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

::-webkit-input-placeholder { /* Chrome */
  color: #1BA948;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #1BA948;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #1BA948;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 4 - 18 */
  color: #1BA948;
  opacity: 1;
}

</style>