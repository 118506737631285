<template>
  <div class="payment-item">
    <img :src="require('@/assets/ic_ruble.svg')" class="icon" alt="">
    <div class="date">{{convertTime(item.timestamp)}}</div>
    <div class="title">Пополнение баланса</div>
    <div class="price-container">
      <div class="price">{{item.amount}}₽</div>
    </div>

  </div>

</template>

<script>
export default {
  name: "item-payment",
  props: {
    item: Object
  },
  methods: {
    convertTime(time) {
      if (time === -1) {
        return 'Отправка...';
      }
      const date = new Date(time * 1000);
      // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


      // const hours = date.getHours();
      // const minutes = "0" + date.getMinutes();
      // return date.getDate() + '.'+ date.getMonth()+'.'+ date.getFullYear()+' '+ hours + ':' + minutes.substr(-2);
      return date.toLocaleDateString()+', '+ date.toLocaleTimeString().slice(0,-3)
    }
  }
}
</script>

<style scoped>

.payment-item {
  text-align: start;
  background: white;
  border-radius: 24px;
  height: max-content;
  box-shadow: 0 0 16px rgba(41, 48, 67, 0.15);
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: max-content 1fr 200px;
  grid-template-rows: max-content max-content;
  grid-template-areas:
      "icon title price"
      "icon date price";
}

.payment-item .price-container {
  height: calc(100%);
  text-align: center;
  border-radius: 0 16px 16px 0;
  padding: 0 32px;
  display: grid;
  background: rgb(50,11,13);
  background: linear-gradient(180deg, rgba(50,11,13,1) 0%, rgba(116,28,31,1) 100%);
  grid-area: price;
}

.payment-item .price-container .price{
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1.8rem;
  line-height: 1.8rem;
  align-self: center;

}

.payment-item .title {
  grid-area: title;
  font-family: "Mont", serif;
  margin-top: 16px;
  margin-left: 16px;
  font-weight: bold;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.payment-item .date{
  grid-area: date;
  font-family: "Mont", serif;
  margin-left: 16px;
  font-weight: bold;
  color: #9b9b9b;
  align-self: start;
  font-size: 1rem;
  line-height: 1rem;
}

.payment-item .icon {
  grid-area: icon;
  display: inline-block;
  margin-top: 8px;
  vertical-align: bottom;
  margin-bottom: 8px;
  margin-left: 16px;
  height: 56px;
  width: 56px;
}


</style>