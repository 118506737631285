<template>
  <pop-up-loading style="text-align: center" v-if="isLoading" :opacity="0.5"/>
  <div class="page-scaffold">
    <top-bar style="grid-area: top-bar" :name="userName"/>
    <div class="safe-zone" style="grid-area: safe-zone-l"/>
    <div class="content" style="grid-area: content">
      <div :class="mainCss">
        <div class="title" style="grid-area: title-balance">Мой баланс</div>
        <div class="title" style="grid-area: title-tariffs">Мои тарифы и услуги</div>
        <div class="title" v-if="!this.isPhysical" style="grid-area: title-documents">Документы</div>
        <div class="title" style="grid-area: title-offers">Специальные предложения</div>
        <div class="balance">
          <widget-balance-main :number="account.personalAccount" :balance="account.balance" :is-phys="isPhysical"
                               @update="getAccount"/>
        </div>
        <div class="tariffs">
          <widget-tariffs-main :is-legal="!isPhysical" :next-payment-date="account.nextPaymentDate"
                               :total-cost="account.totalCost"
                               :services="account.services" :is-t-v="isTv" :is-net="isNet"/>
        </div>
        <div class="offers">
          <item-special-offer v-for="offer in offers"
                              :key="offer.id"
                              :image="offer.imageUrl"
                              :url="offer.linkUrl"/>
        </div>
        <div class="buttons">
          <outlined-button-with-icon @click="isPhysical? this.$router.push('/balance') : this.$router.push('/documents?page=1')
" :icon="require('@/assets/ic_card.svg')"
                                     :title="'Пополнить баланс'"/>
          <outlined-button-with-icon @click="$router.push('/notifications')"
                                     :icon="require('@/assets/ic_notification_bell.svg')" :title="'Уведомления'"/>
          <outlined-button-with-icon @click="$router.push('/chat')" :icon="require('@/assets/ic_chat.svg')"
                                     :title="'Чат с поддержкой'"/>
        </div>

        <div class="documents" v-if="!this.isPhysical">
          <outlined-button-with-icon @click="
        this.$router.push({name: 'Documents', query: {'page': 0}})"
                                     :icon="require('@/assets/ic_reconciliation_report.svg')" :title="'Акт сверки'"/>
          <outlined-button-with-icon @click="
        this.$router.push({name: 'Documents', query: {'page': 1}})"
                                     :icon="require('@/assets/ic_ruble.svg')"
                                     :title="'Счет на оплату'"/>
          <outlined-button-with-icon @click="
        this.$router.push({name: 'Documents', query: {'page': 2}})"
                                     :icon="require('@/assets/ic_certificate_completed_works.svg')"
                                     :title="'Акт выполненных работ'"/>
        </div>
      </div>
    </div>
  </div>
  <div class="safe-zone" style="grid-area: safe-zone-r"/>

</template>

<script>
// import CardContent from "./card-content";
import OutlinedButtonWithIcon from "./outlined-button-with-icon";
import WidgetBalanceMain from "./widget-balance-main";
import WidgetTariffsMain from "./widget-tariffs-main";
import ItemSpecialOffer from "./item-special-offer";
import axios from "axios";
import PopUpLoading from "./pop-up-loading";
import TopBar from "./top-bar";

export default {
  name: "page-main",
  components: {
    TopBar,
    PopUpLoading,
    ItemSpecialOffer,
    WidgetTariffsMain,
    WidgetBalanceMain,
    OutlinedButtonWithIcon,
    // CardContent
  },
  data: () => {
    return {
      userName: "Имя Пользователя",
      isLoading: true,
      isPhysical: true,
      mainCss: 'main-page-phys',
      services: {
        staticIP: "notActive",
        videoSurveillance: "processing",
        smartHome: "active"
      },
      isTv: false,
      isNet: false,
      account: Object,
      offers: Array
    }
  },
  methods: {
    getAccount() {
      this.isLoading = true
      if (localStorage.userType) {
        this.isPhysical = localStorage.userType === "individual"
      }
      if (localStorage.mainCss) {
        this.mainCss = localStorage.mainCss
      }

      axios({
        method: "GET",
        url: "v2/account",
        headers: {Authorization: `Bearer ` + localStorage.token},
      }).then(response => {
        axios({
          method: "GET",
          url: "v1/advertisingOffers",
          headers: {Authorization: `Bearer ` + localStorage.token},
        }).then(response => {
          this.isLoading = false
          this.offers = response.data
        })
        this.account = response.data.account
        localStorage.userType = response.data.account.type
        localStorage.userName = response.data.account.userName
        this.userName = response.data.account.userName
        localStorage.userID = response.data.account.userID
        this.isTv = response.data.account.televisionTariffs.length !== 0
        this.isNet = response.data.account.internetTariffs.length !== 0
        if (response.data.account.type === "individual") {
          localStorage.mainCss = "main-page-phys"
          this.isPhysical = true
          this.mainCss = "main-page-phys"
        } else {
          this.isPhysical = false
          localStorage.mainCss = "main-page"
          this.mainCss = "main-page"

        }
      }).catch(function (error) {
        console.log(error.response.status === 401)
        {
          localStorage.token = ""
          localStorage.userID = ""
          localStorage.mainCss = "main-page-phys"
          localStorage.userName = "Имя пользователя"
          localStorage.userType = "individual"
          this.$router.push("/login")
        }
      });
    }
  },
  mounted() {
    this.getAccount()


  },
}
</script>

<style scoped>


.main-page {
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 48px);
  height: 100%;
  display: grid;
  overflow-y: scroll;
  /*grid-template-columns: minmax(100px, 1fr) 1.7fr 1fr;*/
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: max-content max-content max-content max-content 1fr;
  grid-template-areas:
      "title-balance title-tariffs title-documents"
      "balance tariffs documents"
      "balance tariffs title-offers"
      "balance tariffs offers"
      "buttons tariffs offers";
}

.main-page-phys {
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 36px);
  height: 100%;
  display: grid;
  overflow-y: scroll;
  /*grid-template-columns: minmax(100px, 1fr) 1.7fr 1fr;*/
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: max-content max-content max-content max-content 1fr;
  grid-template-areas:
      "title-balance title-tariffs title-offers"
      "balance tariffs offers"
      "balance tariffs offers"
      "balance tariffs offers"
      "buttons tariffs offers";
}

.title {

  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #FFFFFF;
  grid-area: title;
  text-align: start;
  align-self: end;
  justify-self: start;
  /*white-space: nowrap;*/
  font-size: 1.4rem;
  margin-top: 24px;
  margin-bottom: 24px;
}

.balance {
  grid-area: balance;
}

.tariffs {
  grid-area: tariffs;
}

.offers {
  overflow-y: scroll;
  grid-area: offers;
}

.offers::-webkit-scrollbar {
  width: 0;
  height: 0;
}


.documents {
  padding: 0 16px 0 0;
  grid-area: documents;
}

.buttons {
  padding: 32px 16px 16px 0;
  grid-area: buttons;
}


.page-scaffold {
  background: #141414;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1400px) 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
      "safe-zone-l top-bar safe-zone-r"
      "safe-zone-l content safe-zone-r";
}

.page-scaffold .safe-zone {
}

.page-scaffold .content {
}

</style>