<template>
  <div class="special-offer">
    <img class="image-offer" :src="image" @click="openUrl">
    <!--    <div class="more">Подробнее</div>-->
  </div>

</template>

<script>
export default {
  name: "item-special-offer",
  props: {
    image: String,
    url: String
  },
  methods: {
    openUrl() {
      window.open(this.url, '_blank')
    }
  }
}
</script>

<style scoped>

.more {
  position: absolute;
  padding: 8px 16px;
  border-radius: 24px;
  bottom: 16px;
  right: 16px;
  background: white;
  font-family: "Mont", serif;
  font-weight: bold;
  line-height: 1rem;
  color: #394856;
  font-size: 1rem;
}

.image-offer {
  border-radius: 16px;
  object-fit: fill;
  width: 100%;
  height: 100%;

}

.special-offer {
  position: relative;
  cursor: pointer;
  width: calc(100% - 16px);
  margin-bottom: 24px;
  background: #1BA948;
  height: 200px;
  border-radius: 16px;
}


</style>