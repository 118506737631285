import LoginPage from "../components/page-login";
import MainPage from "../components/page-main";
import BalancePage from "../components/page-balance";
import NotificationsPage from "../components/page-notifications";
import ChatPage from "../components/page-chat";
import Documents from "../components/page-documents";
import Camera from "../components/page-videocamera";
import SmartHouse from "../components/page-smart-house";
import MyServices from "../components/page-my-tariffs";
import PageProfile from "../components/page-profile";
import InternetTariffs from "../components/page-internet-tariffs";
import PageService from "../components/page-service";


import {createWebHistory} from "vue-router";
import {createRouter} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Main",
        meta: {auth: true,},
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        meta: {auth: false,},
        component: LoginPage,
    },
    {
        path: "/balance",
        name: "Balance",
        meta: {auth: true,},
        component: BalancePage,
    },
    {
        path: "/notifications",
        name: "Notifications",
        meta: {auth: true,},
        component: NotificationsPage,
    },
    {
        path: "/chat",
        name: "Chat",
        meta: {auth: true,},
        component: ChatPage,
    },
    {
        path: "/documents",
        name: "Documents",
        meta: {auth: true,},
        component: Documents,
    },
    {
        path: "/camera",
        name: "Camera",
        meta: {auth: true,},
        component: Camera,
    },
    {
        path: "/smart-house",
        name: "Smart House",
        meta: {auth: true,},
        component: SmartHouse,
    },
    {
        path: "/my-tariffs",
        name: "My Tariffs",
        meta: {auth: true,},
        component: MyServices,
    },

    {
        path: "/profile",
        name: "Profile",
        meta: {auth: true},
        component: PageProfile,
    },

    {
        path: "/tariffs",
        name: "Tariffs",
        meta: {auth: true},
        component: InternetTariffs,
    },

    {
        path: "/service",
        name: "Service",
        meta: {auth: true},
        component: PageService,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    const requireAuth = to.matched.some(record => record.meta.auth)

    if (!localStorage.token && requireAuth) {
        next('/login')
    } else {
        if (to.name !== "Documents" || localStorage.userType === "legalEntity") {
            next()
        }else {
            next(from.path)
        }
    }
})


export default router;
