<template>
  <div class="card-container">
    <img class="back" :src="require('@/assets/ic_back_arrow.svg')" alt="" @click="$router.back()">
    <div class="title" :style="'margin-left: '+marginLeft">{{ name }}</div>
    <div id="btnn" v-if="withButton" class="btn" @click="this.$emit('btnClick')">{{ btnText }}</div>
    <div class="divider"/>
    <div class="card-content" style="position: relative">
      <pop-up-loading v-if="isLoading" :title="loadingTitle"/>
      <slot/>
    </div>
  </div>
</template>

<script>

import PopUpLoading from "./pop-up-loading";

export default {
  name: "card-content",
  components: {PopUpLoading},
  props: {
    name: String,
    btnText: {
      type: String,
      default: 'Запросить'
    },
    loadingTitle: {
      type: String,
      default: 'Загрузка...'
    },
    withButton: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      marginLeft: '0px'
    }
  },
  methods: {
    getBtnWidth() {
      this.marginLeft = document.getElementById('btnn').getBoundingClientRect().width + 'px'
    }
  }, mounted() {
    if (this.withButton)
      this.getBtnWidth()
  }
}
</script>

<style scoped>
.card-container {
  margin: 20px 20px 20px 20px;
  background: white;
  height: calc(100% - 20px);
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px 24px 0 0;
  display: grid;
  grid-template-columns: 64px 1fr max-content;
  grid-template-rows: 64px 1px 1fr;
  grid-template-areas:
      "back title btn"
      "divider divider divider"
      "content content content";
}

.card-container .title {
  font-family: "Century Gothic", serif;
  font-weight: bold;
  color: #000000;
  grid-area: title;
  align-self: center;
  justify-self: center;
  margin-right: 64px;
  font-size: 1.5rem;
}

.card-container .back {
  cursor: pointer;
  max-width: 24px;
  grid-area: back;
  align-self: center;
  margin-left: 24px;
  justify-self: start;
}

.card-container .divider {
  background: #e7e7e7;
  height: 1px;
  grid-area: divider;
  width: 100%;
}

.card-container .card-content {
  overflow-y: scroll;
  grid-area: content;
}

.card-container .card-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.btn {
  box-shadow: 0 0 16px rgba(17, 115, 47, 0.6);
  cursor: pointer;
  align-self: center;
  margin-right: 24px;
  border-radius: 10px;
  height: max-content;
  background: #1BA948;
  color: white;
  width: max-content;
  justify-self: end;
  grid-area: btn;
  font-family: "Mont", serif;
  padding: 12px 24px 10px 24px;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

</style>