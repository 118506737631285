<template>
  <div title="СОСать" class="item-gadget" style="position: relative">
    <img class="image" :src="gadget.photos[0]"/>
    <div class="title">
      {{ gadget.title }}
    </div>
    <div class="price">
      Цена: {{ gadget.price }} ₽/мес
    </div>
    <div class="widget-add" style="">
      <img src="@/assets/ic_btn_minus.svg" style="height: 24px; align-self: center;  cursor: pointer;
" alt="" @click="removeGadget()">
      <div class="count" style="align-self: center">{{ this.gadget.quantity }}</div>
      <img @click="addGadget()" src="@/assets/ic_btn_plus.svg" style="height: 24px; align-self: center;   cursor: pointer;
" alt="">
    </div>
    <div class="description">
      <div style="font-size: 1.1rem; text-decoration: underline; margin-bottom: 8px">О гаджете:</div>
      {{ gadget.hint }}
    </div>

  </div>
</template>

<script>
export default {
  name: "item-gadget",
  props: {
    gadget: {
      type: Object,
      default() {
        return {
          quantity: 0,
          price: 0,
          hint: "",
          title: "Название гаджета",
          photos: ["krk"],
          prices: [300],
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    addGadget() {
      this.$emit("add-gadget")
    },
    removeGadget() {
      this.$emit("remove-gadget")
    }
  }
}
</script>

<style scoped>

.item-gadget {
  margin-bottom: 16px;
  margin-right: 16px;
  min-width: 145px;
  max-width: 135px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  padding: 12px;
}


.image {
  object-fit: contain;
  width: 100%;
  height: 110px;
  border-radius: 8px;
}

.title {
  margin-top: 8px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  height: 2rem;
  overflow: hidden;
  align-self: start;
  text-align: left;
}

.price {
  margin-top: 4px;
  font-weight: bold;
  font-size: 0.85rem;
  line-height: 0.85rem;
  margin-bottom: 16px;
  overflow: hidden;
  color: #585858;
  align-self: start;
  text-align: left;
}

.widget-add {
  cursor: default;
  vertical-align: center;
  padding: 4px 16px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  height: 36px;
  box-shadow: 0 0 24px rgba(29, 132, 201, 0.4);
  background: #1BA948;
  border-radius: 16px;
}


.widget-add .count {
  border-radius: 10px;
  align-self: center;
  background: #1BA948;
  color: white;
  font-family: "Mont", serif;
  padding: 6px 12px;
  margin-left: 16px;
  margin-right: 16px;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.description {
  font-size: 0.8rem;
  background: rgba(255, 255, 255, .7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Mont, serif;
  height: calc(100% - 100px);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  cursor: default;
  color: #355b6c;
  position: absolute;
  top: 0;
  text-align: start;
  overflow-wrap: break-word;
  left: 0;
  z-index: 4;
  overflow-y: scroll;
  width: 137px;
  border-radius: 16px;
  padding: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
}

.description:hover {
  visibility: visible;
  opacity: 1;
}

.item-gadget:hover .description {
  visibility: visible;
  opacity: 1;
}


</style>