<template>
  <div class="document-item">
    <img :src="require('@/assets/ic_certificate_completed_works.svg')" class="icon" alt="">
    <div class="date">{{ document.createdAt }}</div>
    <div class="title">{{ document.title }}</div>
    <div class="price-container" @click="download">
      <div class="price" >Скачать</div>
    </div>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: "item-document",
  props: {
    document: Object
  },
  methods: {
    download() {
      axios({
        url: 'document/'+ this.document.id,
        method: 'GET',
        headers: {Authorization: `Bearer ` + localStorage.token},
        responseType: 'blob'
      }).then(response => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fURL = document.createElement('a');
        fURL.href = fileURL;
        fURL.setAttribute('download', this.document.title + '.' + this.document.extension);
        document.body.appendChild(fURL);
        fURL.click();
      });

    }
  }
}
</script>

<style scoped>

.document-item {
  text-align: start;

  padding: 8px;
  background: white;
  border-radius: 24px;
  height: max-content;
  box-shadow: 0 0 16px rgba(41, 48, 67, 0.15);
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content max-content;
  grid-template-areas:
      "icon title btn"
      "icon date btn";
}

.document-item .price-container {
  cursor: pointer;
  height: calc(100%);
  border-radius: 16px;
  padding: 0 48px;
  display: grid;
  background: rgb(50,11,13);
  background: linear-gradient(180deg, rgba(50,11,13,1) 0%, rgba(116,28,31,1) 100%);
  grid-area: btn;
}

.document-item .price-container .price {
  font-family: "Mont", serif;
  margin-top: 4px;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  align-self: center;

}

.document-item .title {
  grid-area: title;
  font-family: "Mont", serif;
  margin-top: 8px;
  margin-left: 16px;
  font-weight: bold;
  color: #000000;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.document-item .date {
  grid-area: date;
  font-family: "Mont", serif;
  margin-left: 16px;
  margin-top: 4px;
  font-weight: bold;
  color: #9b9b9b;
  align-self: start;
  font-size: 1rem;
  line-height: 1rem;
}

.document-item .icon {
  margin-left: 16px;
  align-self: center;
  grid-area: icon;
  display: inline-block;

  height: 36px;
  width: 36px;
}


</style>