<template>
  <div class="widget-balance">
    <div class="top">
      <img :src="require('@/assets/ic_contract_number_white.svg')" style="height: 56px" alt="">
      <div v-if="isPhys" class="title">Лицевой счет №</div>
      <div v-if="isPhys" class="number" style="letter-spacing: 0.4rem;">{{ number === "" ? '- - - - - - -': number }}</div>
    </div>

    <div class="title" style="color: #949494">Текущий баланс:</div>
    <div class="number"
         style="color: #000000; font-size: 3rem; line-height: 3rem; margin-bottom: 24px; margin-top: 16px">
      {{ balance }} ₽
    </div>

    <div class="btn" @click="this.$emit('update')">ОБНОВИТЬ</div>

    <div class="title" style="color: #000000; cursor: pointer" @click="this.$router.push('/balance')">История платежей</div>
  </div>
</template>

<script>
export default {
  name: "widget-balance-main",
  props: {
    isPhys: Boolean,
    number: {
      type: String,
      default: "- - - - - - -"
    },
    balance: {
      type: String,
      default: "-"
    },
  }
}
</script>

<style scoped>

.widget-balance {
  text-align: center;
  margin-right: 24px;
  background: #ffffff;
  width: calc(100% - 24px);
  padding-bottom: 24px;
  box-shadow: 0 0 24px rgba(43, 89, 120, 0.15);
  border-radius: 24px;
}

.widget-balance .top {
  padding: 16px;
  text-align: center;
  background: rgb(50,11,13);
  background: linear-gradient(180deg, rgba(50,11,13,1) 0%, rgba(116,28,31,1) 100%);
  border-radius: 24px 24px 0 0;

}

.number {
  font-family: "Mont", serif;
  font-weight: bold;
  margin-top: 8px;
  line-height: 1.5rem;
  color: #ffffff;
  font-size: 1.6rem;
}

.btn {
  background-image: linear-gradient(to right, #1BA948 0%, #71B280  51%, #1BA948  100%);
  transition: 0.5s;
  cursor: pointer;
  padding: 16px 14px 14px 14px;
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 10px;
  box-shadow: 0 0 24px rgba(27, 200, 72, 0.3);
  width: calc(100% - 48px - 28px);
  color: white;
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  font-size: 1rem;
}

.btn:hover {
  //box-shadow: 0 0 24px rgba(0, 200, 72, 0.6);
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.title {
  font-family: "Mont", serif;
  font-weight: bolder;
  margin-top: 16px;
  line-height: 1rem;
  color: #ffffff;
  font-size: 1rem;
}


</style>